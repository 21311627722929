// Table.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Table.css"; // Import a CSS file for styling
import { getEnvvariable } from "../util/env";
const envConfig = getEnvvariable();

const apiUrl = envConfig.REACT_APP_API_URL;

const TableRow = ({ data }) => {
  const createdDate = new Date(data.created_at);
  const updatedDate = new Date(data.updated_at);
  const timeDiff = updatedDate - createdDate;
  const formattedTimeDiff = new Date(timeDiff).toISOString().substr(11, 8);
  console.log('data',data);
  return (
    <tr>
      {/* <td>{data.entity_id}</td> */}
      <td>{data.job_id}</td>
      <td>{data.uploaded_by}</td>
      <td>
        {(() => {
          let text;
          if (data.file_type === "3") {
            text = "PostProduction%20Upload";
          } else if (data.file_type === "2") {
            text = "DuringProduction%20Upload";
          } else {
            text = "PreProduction%20Upload";
          }

          return (
            <a
              href={`https://storage.googleapis.com/supplier-storage-new/${text}/${encodeURIComponent(
                data.uploaded_file_name
              )}`}
              target="_blank"
            >
              {data.uploaded_file_name}
            </a>
          );
        })()}
      </td>
      <td>
        {(() => {
          let text;
          if (data.file_type === "3") {
            text = "PostProduction%20Error";
          } else if (data.file_type === "2") {
            text = "DuringProduction%20Error";
          } else {
            text = "PreProduction%20Error";
          }

          return (
            <a
              href={`https://storage.googleapis.com/supplier-storage-new/${text}/${encodeURIComponent(
                data.error_file_name
              )}`}
              target="_blank"
            >
              {data.error_file_name}
            </a>
          );
        })()}
      </td>

      {/* <td>
        <a
          href={`https://storage.googleapis.com/supplier-storage-new/PostProduction%20Error/${encodeURIComponent(
            data.error_file_name
          )}`}
          target="_blank"
        >
          {data.error_file_name}
        </a>
      </td> */}
      <td>{data.status}</td>
      <td>
        {data.file_type === "3"
          ? "Post Production"
          : data.file_type === "2"
          ? "Production"
          : "Pre Production"}
      </td>
      <td>{data.no_of_full_row}</td>
      <td>{data.no_of_error_row}</td>
      <td>{formattedTimeDiff}</td>
      {/* <td>{data.created_at}</td> */}
      {/* Add more cells as needed */}
    </tr>
  );
};

const Table = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        const response = await axios.post(
          apiUrl + "/fileuploader/fileuploadhistory"
        );
        // const response = await axios.post('https://sup-portal-service-qa-nrb4qa3iua-df.a.run.app/fileuploader/fileuploadhistory');
        setTableData(response.data.data); // Assuming the data array is under the 'data' key
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };

    fetchDataFromAPI();
  }, []); // The empty dependency array ensures that this effect runs only once, similar to componentDidMount

  return (
    <table className="custom-table">
      <thead>
        <tr>
          {/* <th>Entity ID</th> */}
          <th>Job ID</th>
          <th>Uploaded By</th>
          <th>Uploaded File Name</th>
          <th>Error File Name</th>
          <th>Status</th>
          <th>File Type</th>
          <th>Total on.of rows</th>
          <th>No.of error rows</th>
          <th>Time taken</th>
          {/* <th>Created At</th> */}
          {/* Add more headers as needed */}
        </tr>
      </thead>
      <tbody>
        {tableData.map((rowData) => (
          <TableRow key={rowData.entity_id} data={rowData} />
        ))}
      </tbody>
    </table>
  );
};

export default Table;
