import Head from "../components/page/Head";
import DateFilterContainer from "../components/DateFilterContainer";
import "../assets/css/FileUploadHistory.css";
import { Table } from "reactstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import SiteBar from "./page/SiteBar";
import Pagination from "../components/page/Pagination";
import { Spinner } from "reactstrap";
import loaderGif from '../assets/images/ZZ5H.gif';
import { getEnvvariable } from "../util/env";
const envConfig = getEnvvariable();
const gcsbucket = envConfig.REACT_APP_GCS_BUCKET_NAME;

const FileUploadHistory = () => {
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1); // Initialize with 1
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const [filter, setFilter] = useState(""); 
  const [filterStatus, setFilterStatus] = useState(""); 
  const [filterFileType, setFilterFileType] = useState(""); 
  const [filterDate, setFilterDate] = useState(""); 
  const [filterDateTo, setFilterDateTo] = useState(""); 
  const apiUrl = envConfig.REACT_APP_API_URL;
  // const apiUrl = "http://localhost:8080";

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        setIsLoading(true);
        let formattedDate = "";
        let formattedDateTo = "";
        // console.log("filterDate:", filterDate);
        // console.log("filterDateTo:", filterDateTo);
        
      
        if(filterDate instanceof Date && !isNaN(filterDate)){
          const yyyy = filterDate.getFullYear();
          const mm = String(filterDate.getMonth() + 1).padStart(2, "0");
          const dd = String(filterDate.getDate()).padStart(2, "0");

          formattedDate = `${yyyy}-${mm}-${dd}`;

          // console.log("formattedDate",formattedDate);
        }
        // else{
        //   setIsLoading(false);
        //   // console.error("Invalid date selected");
        // }

        if(filterDateTo instanceof Date && !isNaN(filterDateTo)){
          const yyyya = filterDateTo.getFullYear();
          const mma = String(filterDateTo.getMonth() + 1).padStart(2, "0");
          const dda = String(filterDateTo.getDate()).padStart(2, "0");

          formattedDateTo = `${yyyya}-${mma}-${dda}`;

          // console.log("formattedDateTo",formattedDateTo);
        }
        // else{
        //   setIsLoading(false);
        //   // console.error("Invalid date selected");
        // }

        const queryParams = [];

        if (filter) {
          queryParams.push(`uploaded_by=${filter}`);
        }

        if (filterStatus) {
          queryParams.push(`status=${filterStatus}`);
        }

        if (filterFileType) {
          queryParams.push(`file_type=${filterFileType}`);
        }

        if (filterDate && formattedDate) {
          queryParams.push(`from_date=${formattedDate}`);
        }

        if (filterDateTo && formattedDateTo) {
          queryParams.push(`to_date=${formattedDateTo}`);
        }

        const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
        // console.log("queryString",queryString);

        const fetchUrl = `${apiUrl}/fileuploader/fileuploadhistory${queryString}`;   

        const response = await axios.post(fetchUrl
        // apiUrl + "/fileuploader/fileuploadhistory",
        // `${apiUrl}/fileuploader/fileuploadhistory${filter ? "?uploaded_by=" + filter : ""}`
        );
        const { data, totalrecord } = response.data;
        setTotalPages(Math.ceil(totalrecord / pageSize));
        // const response = await axios.post(
        //   "https://sup-portal-service-qa-nrb4qa3iua-df.a.run.app/fileuploader/fileuploadhistory"
        // );
        // setTableData(response.data.data);

        // Calculate the current page data based on pageSize and currentPage
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const currentPageData = data.slice(startIndex, endIndex);

        setTableData(currentPageData);
        setIsLoading(false);

      } catch (error) {
        console.error("Error fetching data from API:", error);
        setIsLoading(false);
      }
    };

    fetchDataFromAPI();
  }, [currentPage, pageSize, filter, filterStatus, filterFileType, filterDate, filterDateTo]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (tableData && tableData.length) {
      let tempHeaders = Object.keys(tableData[0]);
      setTableHeaders([...tempHeaders]);
    }
  }, [tableData]);

  const getFilterValue = (value)=>{
    setFilter(value);
  }
  const getFilterValueStatus = (value)=>{
    setFilterStatus(value);
  }
  const getFilterValueFiletype = (value)=>{
    setFilterFileType(value);
  }
  const onDateChange = (value)=>{
    setFilterDate(value);
  }
  const onDateChangeTo = (value)=>{
    setFilterDateTo(value);
  }

  return (
    <div className="fileuploadhistory">
      <Head />
      <SiteBar />
      <div className="rectangle-group">
        <div className="group-inner" />
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        {/* <FilteredCardFormContainer /> */}
        <div className="rectangle-container">
        {isLoading ? (
          <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <img src={loaderGif} alt="Loading..." style={{ height: "55px" }} />
        </div>
        // <Spinner
        // color="primary"
        // >
        // {''}
        // </Spinner>
      ) : (
          <div className="rectangle-div">
          <div className="group-child1" />
          <div className="group-child2" />
          <div className="group-child34" />
          <Table className="tableClass">
            <thead className="tableheader">
              <tr>
                <th>Uploaded At</th>
                <th>Upload File Type</th>
                <th>File Name</th>
                <th>Uploaded By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((rowData) => (
                <TableRow key={rowData.entity_id} data={rowData} />
              ))}
            </tbody>
          </Table>
          </div>
           )}
        </div>
      </div>
      <DateFilterContainer
      getFilterValue={getFilterValue}
        getFilterValueStatus={getFilterValueStatus}
        getFilterValueFiletype={getFilterValueFiletype}
        onDateChange={onDateChange}
        onDateChangeTo={onDateChangeTo}
        filterDate="Date Filter"
        propLeft="150px"
        propLetterSpacing="0.4px"
      />
    </div>
  );
};

const TableRow = ({ data }) => {
  const dbDate = data.updated_at;
  const dateObject = new Date(dbDate);
  const formattedTimestamp = dateObject.toISOString().replace("T", " ").replace(/\.\d{3}Z$/, "");

  return (
    <tr>
      <td className="bold-weight">{formattedTimestamp}</td>
      <td className="bold-weight">
        {data.file_type === "3"
          ? "Post Production"
          : data.file_type === "2"
          ? "Production"
          : "Pre Production"}
      </td>
      <td className="bold-weight">
        {(() => {
          let text;
          if (data.file_type === "3") {
            text = "PostProduction%20Upload";
          } else if (data.file_type === "2") {
            text = "DuringProduction%20Upload";
          } else {
            text = "PreProduction%20Upload";
          }

          if (data.uploaded_file_name === "N/A") {
            return <span>N/A</span>;
          }
          return (
            <a className="colorYellow"
              href={`https://storage.googleapis.com/${gcsbucket}/${text}/${encodeURIComponent(
                data.uploaded_file_name
              )}`}
              target="_blank"
            >
              {data.uploaded_file_name}
            </a>
          );
        })()}
      </td>
      <td>{data.uploaded_by}</td>
      {(() => {
        let status;
        if (data.status === "Success") {
          status = <td className="success bold-weight">{data.status}</td>;
        } else {
          let text;
          if (data.file_type === "3") {
            text = "PostProduction%20Error";
          } else if (data.file_type === "2") {
            text = "DuringProduction%20Error";
          } else {
            text = "PreProduction%20Error";
          }
          status = (
            <td className="failedcustm">
              <div>
                <div className="bold-weight" style={{ color: "red" }}>{data.status}</div>
                <div>
                  <div>
                    <span style={{ color: "red" }}>{data.no_of_error_row}</span>
                    <span>/{data.no_of_full_row} Invalid Lines</span>
                  </div>
                  {/* <div> */}
                  <a
                    href={`https://storage.googleapis.com/${gcsbucket}/${text}/${encodeURIComponent(
                      data.error_file_name
                    )}`}
                    target="_blank"
                  >
                    View Details
                  </a>
                  {/* </div> */}
                </div>
              </div>
            </td>
          );
        }
        return status;
      })()}
    </tr>
  );
};
export default FileUploadHistory;
